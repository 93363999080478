import styled from "styled-components"
import media from "styled-media-query"

export const LayoutWrapper = styled.section`
  display: flex

  ${media.lessThan("large")`
    flex-direction: column;
  `}
`
export const LayoutMain = styled.main`
  background: var(--background);
  min-height: 100vh;
  padding: 5rem 6.75rem 0 6.75rem;
  width: 100%;

  body#grid & {
    grid-template-areas:
      "posts"
      "pagination";
  }

  ${media.lessThan("large")`
    padding: 1rem 0 0 0;
  `}
`